<template>
  <v-container my-10>
    <v-layout align-center justify-center>
      <v-progress-circular
        :size="55"
        :width="4"
        indeterminate
        class="mt-12"
        v-if="loading"
        color="primary"
      ></v-progress-circular>
      <v-card v-if="!loading" max-width="650px">
        <v-card-title>
          <span class="headline">Preview</span>
          <v-spacer></v-spacer>
          <v-card-actions>
            <v-btn color="primary" text @click="edit()"
              ><v-icon small class="mx-2">mdi-pencil</v-icon>Edit</v-btn
            >
            <v-btn
              text
              color="red"
              v-if="getCanUserWritePartners"
              @click="deleteFn()"
            >
              <v-icon small class="mx-2">mdi-delete</v-icon>Delete</v-btn
            >
          </v-card-actions>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="12" md="12" lg="12">
                <v-card elevation="0" pa-1 ma-1>
                  <v-card-title class="subtitle-1">Name:</v-card-title>
                  <v-card-subtitle> {{ editedItem.name }}</v-card-subtitle>
                </v-card>
              </v-col>

              <v-col cols="12" sm="12" md="12" lg="12">
                <v-card elevation="0">
                  <v-card-title class="subtitle-1">Users:</v-card-title>
                  <v-card-subtitle>
                    <v-chip
                      v-bind:key="user.uuid"
                      v-for="user in editedItem.users"
                      class="mx-1 my-1"
                    >
                      <span>{{ user.username }}</span>
                    </v-chip>
                  </v-card-subtitle>
                </v-card>
              </v-col>
              <v-col cols="12" sm="12" md="12" lg="12">
                <v-card elevation="0">
                  <v-card-title class="subtitle-1">Address:</v-card-title>
                  <v-card-subtitle>
                    {{ editedItem.address }}
                  </v-card-subtitle>
                </v-card>
              </v-col>
              <v-col cols="12" sm="12" md="12" lg="12">
                <v-card elevation="0">
                  <v-card-title class="subtitle-1">City:</v-card-title>
                  <v-card-subtitle>
                    {{ editedItem.city }}
                  </v-card-subtitle>
                </v-card>
              </v-col>
              <v-col cols="12" sm="12" md="12" lg="12">
                <v-card elevation="0">
                  <v-card-title class="subtitle-1">Country:</v-card-title>
                  <v-card-subtitle>
                    {{ editedItem.region }}
                  </v-card-subtitle>
                </v-card>
              </v-col>
              <v-col cols="12" sm="12" md="12" lg="12">
                <v-card elevation="0">
                  <v-card-title class="subtitle-1"
                    >Contact email (visible to customers):</v-card-title
                  >
                  <v-card-subtitle>
                    {{ editedItem.email }}
                  </v-card-subtitle>
                </v-card>
              </v-col>
              <v-col cols="12" sm="12" md="12" lg="12">
                <v-card elevation="0">
                  <v-card-title class="subtitle-1">Website:</v-card-title>
                  <v-card-subtitle>
                    {{ editedItem.website }}
                  </v-card-subtitle>
                </v-card>
              </v-col>
              <v-col cols="12" sm="12" md="12" lg="12">
                <v-card elevation="0">
                  <v-card-title class="subtitle-1">Phone:</v-card-title>
                  <v-card-subtitle>
                    {{ editedItem.phone }}
                  </v-card-subtitle>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            @click="close"
            v-if="getCanUserWritePartners"
            text
            >Cancel</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-layout>
    <delete-dialog
      :dialog="deleteDialog"
      :item="itemName"
      @agree="remove"
      v-show="false"
    />
  </v-container>
</template>

<script>
import api from "@/utils/api.js";
import DeleteDialog from "@/components/dialogs/DeleteDialog.vue";
import { mapGetters, mapState } from "vuex";
import { store } from "@/store/index";
import { COMPUTE_CAN_USER_WRITE_PARTNERS_PERMISSION } from "@/store/actions/permissions";
export default {
  components: {
    DeleteDialog,
  },
  data: () => ({
    accountView: false,
    loading: true,
    deleteDialog: false,
    itemName: null,
    editedItem: {
      name: "",
      brand_uuids: [],
      crm_group_uuid: "",
      brands: [],
      dealer: [],
      vendor: null,
      domains: [],
      address: "",
      city: "",
      country: "",
      email: "",
      website: "",
      phone: "",
    },
  }),

  computed: {
    ...mapGetters(["getMe", "getCanUserWritePartners"]),
  },

  created() {
    this.$store.dispatch(COMPUTE_CAN_USER_WRITE_PARTNERS_PERMISSION);

    let promises = [api.getDealer(this.$route.params.id), api.getDealerList()];

    Promise.all(promises).then((values) => {
      this.editedItem = values[0].data;
      this.loading = false;
    });
  },
  methods: {
    close() {
      this.$router.push({
        name: "Partners",
      });
    },
    edit() {
      this.$router.push({
        name: "PartnersEdit",
        params: { id: this.$route.params.id, tab: "info" },
      });
    },
    deleteFn() {
      this.deleteDialog = true;
      this.itemName = this.editedItem.name;
    },

    remove(agreed) {
      if (agreed) {
        api.deleteDealer(this.editedItem.uuid).then((resp) => {
          this.$router.push({
            name: "Partners",
          });
        });
      }
      this.deleteDialog = false;
    },
  },
};
</script>
<style lang="scss" scoped></style>
